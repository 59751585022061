import config from './config.js'
import PocketBase from 'pocketbase';

const client = new PocketBase(config.PBURL);

// fetch a paginated records list
export async function getJobsListPB(page, itemCount){
    const resultList = await client.records.getList('posts', page, itemCount, {
        filter: 'created >= "2022-01-01 00:00:00"',
    });
    return resultList;
}

// Making getJobsList call to the API
// export async function getJobsList(){
//     return fetch(`http://${config.APIURL}`)
//     .then((response) => response.json())
// }

// Mocking the getJobsList call for development purposes
export async function getJobsListMock(){
    return Promise.resolve([
        {
            title: "Title 1",
            company: "Tech Company 1",
            location: "City 1",
            description: "This is the first job in the mock response",
            postedTime: new Date()
        },
        {
            title: "Title 2",
            company: "Tech Company 2",
            location: "City 2",
            description: "This is the second job in the mock response",
            postedTime: new Date()
        },
        {
            title: "Title 3",
            company: "Tech Company 3",
            location: "City 3",
            description: "This is the third job in the mock response",
            postedTime: new Date()
        },
    ])
}