import logo from './logo.svg';
import './App.scss';
import * as apiCalls from './apiCalls';
import React, { useState, useEffect } from 'react';
import JobInfo from './Components/jobInfo';

function App() {
  const [jobsList, setJobs] = useState([]);

  useEffect(() => {
    //apiCalls.getJobsListMock().then(result => setJobs(result));
    apiCalls.getJobsListPB()
    .then(result => setJobs(result.items))
    .catch(err => { console.log(err)});
  }, [])


  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        JobsLuft
      </header>
      <JobInfo
        jobsList={jobsList}
      />
      {/* <div className='JobsContainer'>
        {jobsList ?
          jobsList.map((item, count) => (
            <JobItem
              key={count}
              listing={item}
            />)) :
          "Loading..."}
      </div> */}
    </div>
  );
}

export default App;
