import React, { Component } from 'react';

import JobCard from './jobCard';

class JobInfo extends Component {
    componentDidMount() {
        //this.getJobsList();
    }
    // Calling the API
    // https://fusebit.io/blog/node-fetch/?utm_source=www.google.com&utm_medium=referral&utm_campaign=none
    // async getJobsList() {
    //     const res = await fetch('https://conductor-backend.herokuapp.com/events/');
    //     const json = await res.json();
    //     console.log(json);
    // }
    // Use state

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         jobs: {
    //             jobList
    //         },
    //     };
    // }
    // static defaultProps = {
    //     jobsList: this.props.jobsList
    // }
    //  {
    //      title: "Frontend Developer",
    //      company: "Cypher Learning INC",
    //      ctc: 20000,
    //      location: "South Africa",
    //      date_posted: "12 July 2022"
    //  }


    render() {
        return (
            <div class="d-flex flex-row flex-wrap justify-content-center my-5">
                {this.props.jobsList.map((j) => (
                    <JobCard
                        title={j.title}
                        company={j.company}
                        description={j.description}
                        ctc={j.salary}
                        location={j.location}
                        date_posted={j.created}
                        link_to_post={j.posting_link}
                    />
                ))}
            </div>
        );
    }
}

export default JobInfo;