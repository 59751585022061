import React, { Component } from 'react';
// import { Card } from 'react-bootstrap';
import './jobCard.scss'
import { Card } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
class JobCard extends Component {
    render() {
        return (

            <Card>
                <Card.Body>
                    <Card.Title>{this.props.title}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">{this.props.company}</Card.Subtitle>
                    <Card.Text>
                        <div>{this.props.description}</div>
                        <div><strong>Cost To Company:</strong> {this.props.ctc || 'unavailable'}</div>
                        <div><strong>Location:</strong> {this.props.location}</div>
                    </Card.Text>
                    <a rel="noreferrer" target="_blank" class="btn btn-primary" href={this.props.link_to_post}>View</a>
                </Card.Body>
            </Card>


            // <Container >

            //     <Row >
            //         <Col lg={3}>

            //         </Col >
            //     </Row>
            // </Container>



        );
    }
}

export default JobCard;